import React, { useEffect, useState } from "react";

import { useCreateUnitFormik } from "./useCreateUnitFormik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Mapbox, { LocationType } from "../../../components/shared/Mapbox/Mapbox";
import { apiCall } from "../../../api/Api";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import Button from "../../../components/shared/Button/Button";
import InputField from "../../../components/shared/InputField/InputField";
import chevronDown from "../../../assets/svg/chevron-down-gray.svg";
import markerIcon from "../../../assets/svg/marker.svg";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";

import "./CreateUnit.scss";

const CreateUnit = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [location, setLocation] = useState<LocationType | null>();
  const [locationError, setLocationError] = useState(false);
  const [locationRequiredError, setLocationRequiredError] = useState(false);
  const [locationStreetRequiredError, setLocationStreetRequiredError] =
    useState(false);
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();

  const formik = useCreateUnitFormik({
    onSubmit: async (values: any) => {
      if (location === undefined) {
        setLocationRequiredError(true);
        return;
      }
      if (location === null) {
        setLocationError(true);
        return;
      }
      if (location.street === null) {
        setLocationStreetRequiredError(true);
        return;
      }

      try {
        setIsButtonLoading(true);
        const data = {
          name: values.unitName,
          phone: values.phoneNumber,
          longitude: location.longitude,
          latitude: location.latitude,
          city: location.city,
          address: location.full_address,
        };

        await apiCall("POST", "b2b/organization-unit/", data);

        globalCtx.openMessageModal(
          "success",
          t("staticMessage.unitCreatedSuccess"),
        );

        setTimeout(() => {
          navigate("/units");
        }, 3000);
      } catch (error: any) {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsButtonLoading(false);
      }
    },
  });

  useEffect(() => {
    if (location !== undefined) {
      setLocationRequiredError(false);

      if (location !== null) {
        setLocationError(false);

        if (location.street !== null) {
          setLocationStreetRequiredError(false);
        }
      }
    }
  }, [location]);

  return (
    <div className="CreateUnitContainer">
      <div className="CreateUnitContainer__head">
        <p className="CreateUnitContainer__head-title">{t("units.title")}</p>
      </div>
      <div className="CreateUnitContainer__body">
        <div className="CreateUnit">
          <div className="CreateUnit__header">
            <p className="CreateUnit__title">
              <img
                alt="pic"
                className="CreateUnit__title-back"
                src={chevronDown}
                onClick={() => {
                  navigate("/units");
                }}
              />
              {t("units.createUnit.title")}
            </p>
          </div>
          <div className="CreateUnit__body">
            <div className="CreateUnit__form">
              <div className="CreateUnit__inputs">
                <div className="CreateUnit__input">
                  <InputField
                    type="text"
                    placeholder={t("units.createUnit.unitName.placeholder")}
                    name="unitName"
                    value={formik.values.unitName}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />
                  {formik.errors.unitName && formik.touched.unitName && (
                    <h5 className="CreateUnit__input-error">
                      {formik.errors.unitName as string}
                    </h5>
                  )}
                </div>{" "}
                <div className="CreateUnit__input">
                  <InputField
                    type="text"
                    placeholder={`${t("units.createUnit.phoneNumber.placeholder")}`}
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />
                  {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                    <h5 className="CreateUnit__input-error">
                      {formik.errors.phoneNumber as string}
                    </h5>
                  )}
                </div>
              </div>
              <div className="CreateUnit__map">
                <Mapbox
                  locationData={(location) => {
                    setLocation(location);
                  }}
                />
              </div>
              <div className="CreateUnit__map-description">
                {t("mapComponent.descriptionOne")}{" "}
                <img alt="pic" width="10px" src={markerIcon} />{" "}
                {t("mapComponent.descriptionTwo")}
              </div>
              {locationError && (
                <h5 className="CreateUnit__map-error">
                  {t("mapComponent.error")}
                </h5>
              )}{" "}
              {locationRequiredError && (
                <h5 className="CreateUnit__map-error">
                  {t("mapComponent.errorRequired")}
                </h5>
              )}{" "}
              {locationStreetRequiredError && (
                <h5 className="CreateUnit__map-error">
                  {t("mapComponent.errorStreetRequired")}
                </h5>
              )}
              <div className="CreateUnit__footer">
                <Button
                  isLoading={isButtonLoading}
                  type="submit"
                  className="CreateUnit__footer-button"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  label={t("units.createUnit.button")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUnit;
