import React, {useEffect, useState, useRef} from "react";
import {useReactToPrint} from "react-to-print";
import cs from "classnames";
import {getTimeBetweenDates} from "../../../lib/helpers/getTimeBetweenDates";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {useTranslation} from "react-i18next";
import verified from "../../../assets/svg/verified-icon-green.svg";
import Button from "../../../components/shared/Button/Button";
import {PhotoProvider, PhotoView} from "react-image-previewer";
import {CloseButton} from "react-image-previewer/ui";
import ContactModal from "../../../components/shared/ContactModal/ContactModal";
import "./CV.scss";
import {convertMinsToHrsMins} from "../../../lib/helpers/convertMinsToHrsMins";
import {EngineeringOutlined, SchoolOutlined, WorkOutlineOutlined} from "@mui/icons-material";


interface SkillLevel {
  level: string,
  name: string
}

const CV = (props: any) => {
  const {person, jobName, jobId, shiftTotal} = props;
  let selectedLanguage = localStorage.getItem("lang");
  const [applicantStatus, setApplicantStatus] = useState<string>(
    person?.selected_shifts && person?.selected_shifts.length > 0 ? "Applied Shifts" : "Personal Details",
  );
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    documentTitle: "My-CV",
    onAfterPrint: () => console.log("Print success!"),
  });

  const [experience, setExperience] = useState<any>(person?.experience);
  const [isLoading, setIsLoading] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false); // State for ContactModal
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const globalCtx = useGlobalContext();
  const {t} = useTranslation("common");

  const skillLevels: SkillLevel[] = [
    {level: "beginner", name: t("beginner")},
    {level: "intermediate", name: t("intermediate")},
    {level: "advanced", name: t("advanced")},
    {level: "expert", name: t("expert")},
  ];


  const getSkillSource = (skill: any) => {
    if (skill.experiences?.length > 0) {
      const exp = experience.find((exp: any) => exp.id === skill.experiences[0]);
      return exp ? `${exp.job_title} ${exp.company_name}` : "";
    } else if (skill.educations?.length > 0) {
      const education = person.education.find((edu: any) => edu.id === skill.educations[0]);
      return education ? `${education.field_of_study} ${education.school_name}` : "";
    }
    return "";
  };

  return (
    <section className="CV" ref={contentRef}>
      <div className="CV__header">
        <div className="CV__header-container">
          <PhotoProvider
            maskOpacity={0.7}
            overlayRender={(props) => {
              return <CloseButton onClick={props.onClose}/>;
            }}
          >
            <PhotoView src={person.applicant?.profile?.picture_url}>
              <img
                src={person.applicant?.profile?.picture_url}
                alt="applicants image"
                className="CV__person-image"
              />
            </PhotoView>
          </PhotoProvider>

          <div className="CV__header-info">
            <div className="CV__name-container">
              <h2
                className="CV__name">{`${person.applicant?.profile?.first_name} ${person.applicant?.profile?.last_name}`}</h2>
              {person.applicant?.verified && (
                <img
                  src={verified}
                  alt="verified icon"
                  className="CV__verified-icon"
                />
              )}
            </div>
            <div className="CV__role">{jobName}</div>
          </div>
        </div>
      </div>
      <section className="CV__container">
        {person?.selected_shifts && person?.selected_shifts.length > 0 && (
          <div
            onClick={() => setApplicantStatus("Applied Shifts")}
            className={cs(
              "CV__container-item",
              applicantStatus === "Applied Shifts" &&
              "CV__container-item--active",
            )}
          >
            {t("jobs.applicantSidebar.appliedShifts")}
          </div>
        )}
        <div
          onClick={() => setApplicantStatus("Personal Details")}
          className={cs(
            "CV__container-item",
            applicantStatus === "Personal Details" &&
            "CV__container-item--active",
          )}
        >
          {t("profileCV")} (CV)
        </div>
        <div
          onClick={() => setApplicantStatus("History")}
          className={cs(
            "CV__container-item",
            applicantStatus === "History" &&
            "CV__container-item--active",
          )}
        >
          {t("history")}
        </div>
      </section>
      {applicantStatus === "Applied Shifts" && (
        <section className="CV__experience">
          {person?.selected_shifts.map((item: any, index: number) => (
            // <div className="CV__experience-item" key={index}>
            <div className="ShortTermJobCard__shift-card-body">
              <div>
                <div className="ShortTermJobCard__shift-card-start">
                  {" "}
                  {`${daysOfTheWeek[new Date(item.start_time).getDay()]} ${new Date(item.start_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric"
                  })}`}
                  {new Date(item.end_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric",
                  }) !==
                  new Date(item.start_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric",
                  })
                    ? ` - ${daysOfTheWeek[new Date(item.end_time).getDay()]} ${new Date(item.end_time).toLocaleString("en-GB", {
                      month: "short",
                      day: "numeric"
                    })} `
                    : ""}
                </div>

                <div className="NewJobForm__shift-card-end">
                  {`${new Date(item.start_time).toLocaleString("en-GB", {
                    hour: "numeric",
                    minute: "numeric"
                  })} ${t("jobs.shortTermJobCard.to")} ${new Date(item.end_time).toLocaleString("en-GB", {
                    hour: "numeric",
                    minute: "numeric"
                  })} `}
                </div>
              </div>
              {shiftTotal && (
                <div className="ShortTermJobCard__shift-card-total">
                  {" "}
                  {convertMinsToHrsMins(
                    getTimeBetweenDates(
                      new Date(item.start_time),
                      new Date(item.end_time),
                    ).minutes,
                  )}{" "}
                  total
                </div>
              )}
            </div>
            // </div>
          ))}
        </section>
      )}
      {applicantStatus === "Personal Details" && (
        <>
          <section className="CV__personal-details">
            <div className="CV__personal-details-item">
              <p className="CV__personal-details-label">
                {t("jobs.applicantSidebar.personalDetails.gender")}
              </p>
              <p className="CV__personal-details-value">
                {person?.applicant?.profile.gender === "M" ? t("male") : t("female")}
              </p>
            </div>
            <div className="CV__personal-details-item">
              <p className="CV__personal-details-label">
                {t("jobs.applicantSidebar.personalDetails.email")}
              </p>
              <p className="CV__personal-details-value">
                {person?.applicant?.email}
              </p>
            </div>
            <div className="CV__personal-details-item">
              <p className="CV__personal-details-label">
                {t("jobs.applicantSidebar.personalDetails.phoneNumber")}
              </p>
              <p
                className="CV__personal-details-value"
                onClick={() =>
                  person?.applicant?.phone_number && setOpenContactModal(true)
                }
              >
                {person?.applicant?.phone_number ? (
                  <>
                    {person.applicant.phone_number}
                    <i
                      className="fa fa-phone"
                      aria-hidden="true"
                      style={{marginLeft: "10px"}}
                    ></i>
                    <i
                      className="fa fa-whatsapp"
                      aria-hidden="true"
                      style={{color: "#25D366", marginLeft: "10px"}}
                    ></i>
                  </>
                ) : (
                  t("jobs.applicantSidebar.personalDetails.noPhoneNumber")
                )}
              </p>
            </div>
            <div className="CV__personal-details-item">
              <p className="CV__personal-details-label">
                {t("jobs.applicantSidebar.personalDetails.birthDate")}
              </p>
              <p className="CV__personal-details-value">
                {" "}
                {new Date(
                  person?.applicant?.profile.date_of_birth,
                ).toLocaleString("en-GB", {
                  month: "short",
                  year: "numeric",
                  day: "numeric",
                })}
              </p>
            </div>
            <div className="CV__personal-details-item">
              <p className="CV__personal-details-label">
                {t("jobs.applicantSidebar.personalDetails.location")}
              </p>
              <p className="CV__personal-details-value">
                {person?.applicant?.profile.address}
              </p>
            </div>

            <div className="CV__section-title">
              <div className="CV__section-title_icon">
                <WorkOutlineOutlined/>
                {t("jobs.applicantSidebar.experience.title")}
              </div>
            </div>
            <p className="CV__personal-details-value">

              {experience && experience.length > 0 ? (
                experience.map((item: any, index: number) => (
                  <div className="CV__experience-item" key={index}>
                    <h2 className="CV__experience-item-title">
                      {item?.job_title}
                    </h2>
                    <p>{item?.company_name}</p>
                    <div className="CV__experience-item-date">
                      {`${new Date(item?.start_date).toLocaleString("en-GB", {
                        month: "short",
                        year: "numeric"
                      })} - ${item?.end_date === null ? "Present" : new Date(item?.end_date).toLocaleString("en-GB", {
                        month: "short",
                        year: "numeric"
                      })} `}
                      <span>&middot;</span>
                      {item?.end_date === null ? (
                        <span>
                      {`${
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(),
                          ).days / 365,
                        ) !== 0
                          ? `${Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365)}yr `
                          : ``
                      }`}
                          {`${
                            getTimeBetweenDates(
                              new Date(item?.start_date),
                              new Date(),
                            ).days /
                            365 -
                            Math.round(
                              getTimeBetweenDates(
                                new Date(item?.start_date),
                                new Date(),
                              ).days / 365,
                            ) !==
                            0 &&
                            (
                              getTimeBetweenDates(
                                new Date(item?.start_date),
                                new Date(),
                              ).days /
                              365 -
                              Math.round(
                                getTimeBetweenDates(
                                  new Date(item?.start_date),
                                  new Date(),
                                ).days / 365,
                              )
                            )
                              .toString()
                              .split(".")[1][0] !== "0"
                              ? ` ${(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365 - Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365)).toString().split(".")[1][0]} mos`
                              : ""
                          }`}
                    </span>
                      ) : (
                        <span>
                      {` ${
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(item?.end_date),
                          ).days / 365,
                        ) !== 0
                          ? `${Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365)}yr `
                          : ``
                      }`}
                          {`${
                            getTimeBetweenDates(
                              new Date(item?.start_date),
                              new Date(item?.end_date),
                            ).days /
                            365 -
                            Math.round(
                              getTimeBetweenDates(
                                new Date(item?.start_date),
                                new Date(item?.end_date),
                              ).days / 365,
                            ) !==
                            0
                              ? ` ${(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365 - Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365)).toString().split(".")[1][0]} mos`
                              : ""
                          }`}
                    </span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="CV__experience-item">
                  <div>{t("hasNoExperienceYet")}</div>
                </div>
              )}
            </p>

            <div className="CV__section-title">
              <div className="CV__section-title_icon">
                <SchoolOutlined/>
                {t("education")}
              </div>
            </div>


            <div className="CV__personal-details-item">

              <p className="CV__personal-details-value">
                {person?.education && person.education.length > 0 ? (
                  person?.education.map((item: any, index: number) => (
                    <div key={index}>
                      {/*{item.degree} në {item.field_of_study} në{" "}*/}
                      {/*{item.school_name}*/}
                      <div className="CV__experience-item" key={item.id || 0}>
                        <h2 className="CV__experience-item-title">
                          {item.degree} në {item.field_of_study}
                        </h2>
                        <p>{item.school_name}</p>
                        <div className="CV__experience-item-date">
                          {`${new Date(item.start_date).toLocaleString("en-GB", {
                            month: "short",
                            year: "numeric",
                          })} - ${item.end_date === null ? "Present" : new Date(item.end_date).toLocaleString("en-GB", {
                            month: "short",
                            year: "numeric",
                          })}`}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="CV__experience-item">
                    <div>{t("hasNoEducationYet")}</div>
                  </div>
                )}
              </p>

            </div>

            <div className="CV__section-title">
              <div className="CV__section-title_icon">
                <EngineeringOutlined/>
                {t("skills")}
              </div>
            </div>

            <div className="CV__personal-details-item">
              <p className="CV__personal-details-value">
                {person?.skills && person.skills.length > 0 ? (
                  person.skills.map((item: any, index: number) => {
                    const skillLevel = skillLevels.find((level) => level.level === item.skill_level);
                    return (
                      <div key={index}>
                        <div className="CV__experience-item" key={item.id || 0}>
                          <h2 className="CV__experience-item-title">
                            {item.skill_name}
                          </h2>
                          <p>{t("skillLevel")} : {skillLevel ? skillLevel.name : t("unknownLevel")}</p>
                          <div className="CV__experience-item-date">
                            {getSkillSource(item)}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="CV__experience-item">
                    <div>{t("noSkillsYet")}</div>
                  </div>
                )}
              </p>
            </div>

          </section>
        </>
      )}
      {applicantStatus === "History" && (
        <section className="CV__experience">
          <p className="CV__experience-item"> {t("applicantHistoryInYourCompany")} </p>
          <div className="CV__personal-details-item">
            <p className="CV__personal-details-value">
              {person?.history && person.history.length > 0 ? (
                person.history.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="CV__experience-item" key={item.id || 0}>
                        <h2 className="CV__experience-item-title">
                          {item.job.job_title.sq}
                        </h2>
                        {item.comment !== null ? (
                          <p>{t("comment")} : <span style={{color: "black"}}>{item.comment}</span></p>
                        ) : (
                          <p>{t("youDidNotCommentYet")}</p>
                        )}
                        {/*<p>{t("comment")} : {item.comment ? item.comment : t("youDidNotCommentYet")}</p>*/}
                        <div className="CV__experience-item-date">
                          <p>{t("jobs.applicationCard.appliedOn")} : {new Date(item.applied_on).toLocaleDateString("en-US")}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="CV__experience-item">
                  <div>{t("hasNotAppliedBeforeInYourCompany")}</div>
                </div>
              )}
            </p>
          </div>
        </section>
      )}
      <section className="CV__footer">
        <Button
          type="button"
          label={t("downloadCV")}
          onClick={() => {
            reactToPrintFn();
          }}
          className="CV__accept-btn"
          disabled={isLoading || person.status === "cancelled"}
        />
      </section>
      {openContactModal && (
        <ContactModal
          phoneNumber={person?.applicant?.phone_number}
          onClose={() => setOpenContactModal(false)}
        />
      )}
    </section>
  );
};

export default CV;
