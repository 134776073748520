import React, { useState } from "react";

import { useSignInFormik } from "./useSignInFormik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { apiCall, apiCallWithoutAuth } from "../../../api/Api";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { handleErrors } from "../../../lib/helpers/handleErrors";
import Button from "../../../components/shared/Button/Button";
import InputField from "../../../components/shared/InputField/InputField";
import eye from "../../../assets/svg/eye.svg";
import eyeSlash from "../../../assets/svg/eye-slash.svg";
import googleIcon from "../../../assets/images/google-icon.png";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import "./SignInForm.scss";

const SignInForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const globalCtx = useGlobalContext();
  const authCtx = useAuthContext();
  const provider = new GoogleAuthProvider();

  const formik = useSignInFormik({
    onSubmit: async (values: any) => {
      try {
        setIsLoading(true);
        //SIGN IN USER
        const userCredential = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password,
        );
        //IF EMAIL IS NOT VERIFIED
        if (!userCredential.user.emailVerified) {
          //VERIFY EMAIL
          await apiCallWithoutAuth("post", "b2b/user/verify-email/", {
            email: userCredential.user.email,
          });
          //PROCEED
          navigate("/send-email");
          return;
        }
        //GET USER PROFILE
        let profileData = await apiCall("GET", "b2b/user/profile/");

        //IF PHONE NUMBER IS NOT VERIFIED
        // if (profileData?.data?.user?.phone_number === null) {
        //   navigate("/verify-phone-number");
        //   return;
        // }

        //IF PROFILE IS NOT CREATED
        if (profileData.data.profile === null) {
          navigate("/create-company");
          return;
        }

        //IF COMPANY IS NOT CREATED
        if (profileData.data.user.organization === null) {
          navigate("/create-company");
          return;
        }

        //CHECK USER LANGUAGE
        if (
          profileData.data.profile.language !==
          globalCtx.selectedLanguage.keyword
        ) {
          //CHANGE LANGUAGE
          await apiCall("POST", "b2b/user/profile/", {
            phone_number: profileData.data.user.phone_number,
            language: globalCtx.selectedLanguage.keyword,
          });

          //GET EDITED PROFILE DATA AND REPLACE IT
          profileData = await apiCall("GET", "b2b/user/profile/");
        }

        //GET COMPANY
        const companyData = await apiCall("GET", "b2b/organization/");

        // SAVE PROFILE DATA TO LOCAL STORAGE AND RESET STATE
        authCtx.setDataToLocalStorage(
          profileData.data.profile,
          companyData.data,
        );
        //PROCEED
        navigate("/");
      } catch (error: any) {
        const errorMessage = handleErrors(error, t);
        globalCtx.openMessageModal("error", errorMessage);
        authCtx.signOutFirebaseUser();
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleContinueWithGoogle = async () => {
    try {
      setIsLoadingGoogle(true);
      //SIGN IN WITH GOOGLE
      await signInWithPopup(auth, provider);

      //GET USER PROFILE
      let profileData = await apiCall("GET", "b2b/user/profile/");

      //IF PHONE NUMBER IS NOT VERIFIED
      // if (profileData?.data?.user?.phone_number === null) {
      //   navigate("/verify-phone-number");
      //   return;
      // }

      //IF PROFILE IS NOT CREATED
      if (profileData.data.profile === null) {
        navigate("/create-company");
        return;
      }

      //IF COMPANY IS NOT CREATED
      if (profileData.data.user.organization === null) {
        navigate("/create-company");
        return;
      }

      //CHECK USER LANGUAGE
      if (
        profileData.data.profile.language !== globalCtx.selectedLanguage.keyword
      ) {
        //CHANGE LANGUAGE
        await apiCall("POST", "b2b/user/profile/", {
          language: globalCtx.selectedLanguage.keyword,
        });

        //GET EDITED PROFILE DATA AND REPLACE IT
        profileData = await apiCall("GET", "b2b/user/profile/");
      }

      //GET COMPANY
      const companyData = await apiCall("GET", "b2b/organization/");

      // SAVE PROFILE DATA TO LOCAL STORAGE AND RESET STATE
      authCtx.setDataToLocalStorage(profileData.data.profile, companyData.data);
      //PROCEED
      navigate("/");
    } catch (error: any) {
      const errorMessage = handleErrors(error, t);
      globalCtx.openMessageModal("error", errorMessage);
      authCtx.signOutFirebaseUser();
    } finally {
      setIsLoadingGoogle(false);
    }
  };

  return (
    <form className="SignInForm" onSubmit={formik.handleSubmit}>
      <div className="SignInForm__container">
        <h2 className="SignInForm__title">{t("signIn.form.title")}</h2>
        <div className="SignInForm__google">
          <div
            className="SignInForm__google-container"
            onClick={() => {
              handleContinueWithGoogle();
            }}
          >
            {isLoadingGoogle ? (
              <div className="SignInForm__google-spinner"></div>
            ) : (
              <>
                <img
                  alt="pic"
                  src={googleIcon}
                  className="SignInForm__google-icon"
                />
                <div className="SignInForm__google-text">
                  {t("signIn.form.googleText")} Google
                </div>
              </>
            )}
          </div>
        </div>

        <div className="SignInForm__or">{t("signIn.form.or")}</div>
        <div className="SignInForm__input">
          <InputField
            type="text"
            placeholder={t("signIn.form.email.placeholder")}
            name="email"
            value={formik.values.email}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
          />
          {formik.errors.email && formik.touched.email && (
            <h5 className="SignInForm__input-error">
              {formik.errors.email as string}
            </h5>
          )}
        </div>
        <div className="SignInForm__input">
          <InputField
            type={showPassword ? "text" : "password"}
            placeholder={t("signIn.form.password.placeholder")}
            name="password"
            value={formik.values.password}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {
              setShowPassword(!showPassword);
            }}
            icon={showPassword ? eyeSlash : eye}
          />
          {formik.errors.password && formik.touched.password && (
            <h5 className="SignInForm__input-error">
              {formik.errors.password as string}
            </h5>
          )}
        </div>
        <h5
          className="SignInForm__forgotPassword"
          onClick={() => {
            navigate("/reset-password");
          }}
        >
          {t("signIn.form.forgotPassword")}
        </h5>
        <div className="SignInForm__button">
          <Button
            isLoading={isLoading}
            onClick={() => {}}
            label={t("signIn.form.button")}
            type="submit"
          />
        </div>
        <div className="SignInForm__link">
          <p className="SignInForm__link-text">
            {t("signIn.form.registerLink.registerLinkOne")}{" "}
            <span
              onClick={() => {
                navigate("/sign-up");
              }}
            >
              {t("signIn.form.registerLink.registerLinkTwo")}
            </span>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SignInForm;
