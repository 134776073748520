import React, {useEffect, useState, useRef} from "react";
import {apiCall} from "../../../../api/Api";
import ApplicationCard from "../ApplicationCard/ApplicationCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../../../components/shared/LoadingComponent/LoadingComponent";
import RightSidebar from "../../../../components/shared/RightSidebar/RightSidebar";
import ApplicantSidebar from "../ApplicantSidebar/ApplicantSidebar";
import chevronLeft from "../../../../assets/svg/chevron-down-gray.svg";
import cs from "classnames";
import {useNavigate} from "react-router-dom";
import reloadIcon from "../../../../assets/svg/Refresh_icon.svg";

import {useTranslation} from "react-i18next";

import "./LongTermApplications.scss";
import {useOutsideClickDetection} from "../../../../lib/hooks/useOutSideClickDetection";
import 'mdui/mdui.css';
import 'mdui';
import {Button, Chip, Stack, styled} from "@mui/material";
import {Close, Done, Download} from "@mui/icons-material";
import WarningModal from "../../../../components/shared/WarningModal/WarningModal";
import {useAuthContext} from "../../../../context/AuthContext/AuthContext";
import {handleErrors} from "../../../../lib/helpers/handleErrors";
import {useGlobalContext} from "../../../../context/GlobalContext/GlobalContext";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../../../firebaseConfig";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import ApplicationTable from "../ApplicationTable/ApplicationTable";

const LongTermApplications = () => {
  let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
  const [showAsTable, setShowAsTable] = useState(localStorage.getItem("showAsTable") === "true");
  let selectedLanguage = localStorage.getItem("lang");
  const authCtx = useAuthContext();
  const globalCtx = useGlobalContext();
  const [job, setJob] = useState(jobStorage);
  const [allApplications, setAllApplications] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error] = useState("");
  const [nextPage, setNextPage] = useState<any>("");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openRightSidebar, setOpenSidebar] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState<any>({});
  const [refreshCalled, setRefreshCalled] = useState(false);
  const rightSidebarRef = useRef(null);
  const {t} = useTranslation("common");
  const navigate = useNavigate();
  let applicantFilters = new Map<string, string>(
    [
      [t("jobs.applications.all"), ''],
      [t("jobs.applications.accepted"), 'accepted'],
      [t("jobs.applications.favorite"), 'favourite'],
      [t("jobs.applications.canceled"), 'cancelled'],
      [t("dismissed"), 'dismissed']
    ]
  );
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [showPermissionRequiredModal, setShowPermissionRequiredModal] = useState(false);

  const hideRightSidebar = () => {
    setOpenSidebar(false);
    setSelectedApplicant({});
  };

  useEffect(() => {
    if (showPermissionRequiredModal) {
      logEvent(analytics, "need_to_subscribe_modal_opened", {
        organization: authCtx.localOrganization.name || "unknown",
      });
      logEvent(analytics, "download_csv_button_clicked", {
        organization: authCtx.localOrganization.name || "unknown",
      });
    }
  }, [showPermissionRequiredModal]);

  useEffect(() => {
    setLoading(true);
    refresh();
  }, [selectedFilter]);

  const downloadApplicantsAsCSV = () => {
    apiCall("GET", `b2b/applications/${job.id}/csv`)
      .then((res) => {
        const csvBlob = new Blob([res], {type: "text/csv"});
        const csvUrl = window.URL.createObjectURL(csvBlob);

        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = csvUrl;
        link.download = `applications_${job.job_title.title?.sq}.csv`; // Set the desired file name
        link.target = "_blank";
        link.rel = "noopener noreferrer";

        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // globalCtx.openMessageModal(
        //   "success",
        //   t("staticMessage.downloadCsvSuccess"),
        // );
      })
      .catch((error: any) => {
        const errorMessage = handleErrors(error, t);
        globalCtx.openMessageModal("error", errorMessage);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!isCalled) {
      setIsLoading(true);
      apiCall("GET", `b2b/applications/${job.id}/?status=${selectedFilter}&limit=10&offset=0`)
        .then((res) => {
          setAllApplications(res.data.results);
          setNextPage(res.data?.next);
          setIsCalled(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, []);

  const refresh = () => {
    setAllApplications([]);
    setIsLoading(true);
    apiCall("GET", `b2b/applications/${job.id}/?status=${selectedFilter}&limit=10&offset=0`)
      .then((res) => {
        setAllApplications(res.data.results);
        setNextPage(res.data?.next);
        setIsCalled(true);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const loadMore = () => {
    if (nextPage !== null) {
      setIsLoading(true);
      let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2b/applications/${job.id}/?status=${selectedFilter}&${resultUrl}`)
        .then((res) => {
          setAllApplications([...allApplications, ...res.data.results]);
          setIsCalled(true);
          setNextPage(res.data?.next);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#1890ff',
          ...theme.applyStyles('dark', {
            backgroundColor: '#177ddc',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
      ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255,255,255,.35)',
      }),
    },
  }));

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  useOutsideClickDetection(rightSidebarRef, () => {
    hideRightSidebar();
  });

  return (
    <section className="LongTermApplications">
      <div className="LongTermApplications__header">
        <div className="LongTermApplications__title-container">
          <h1 className="LongTermApplications__default-title">
            {t("jobs.title")}
          </h1>
          {isLoading ? (
            <div className="Jobs__spinner"/>
          ) : (
            <img
              src={reloadIcon}
              alt="reload icon"
              className={cs(
                "LongTermApplications__reload-icon",
                refreshCalled && "LongTermApplications__reload-icon--disable",
              )}
              onClick={() => {
                if (!refreshCalled) {
                  refresh();
                  setRefreshCalled(true);
                  setTimeout(() => {
                    setRefreshCalled(false);
                  }, 10000);
                }
              }}
            />
          )}
        </div>
        <section className="LongTermApplications__container">
          <div
            onClick={() => navigate("/jobs/short-term-not-assigned")}
            className={cs("LongTermApplications__container-item")}
          >
            {t("jobs.shortTermTabLabel")}
          </div>
          <div
            onClick={() => {
              navigate("/jobs/long-term-not-assigned");
            }}
            className={cs(
              "LongTermApplications__container-item",
              "LongTermApplications__container-item--active",
            )}
          >
            {t("jobs.longTermTabLabel")}
          </div>
        </section>
        <div className="LongTermApplications__header-chips">
          <Stack direction="row" spacing={1}>
            {Array.from(applicantFilters).map(([key, value]) => (
              <Chip
                key={key}
                label={key}
                icon={selectedFilter === value ? <Done/> : undefined}
                clickable
                onClick={() => {
                  setSelectedFilter(value);  // Set state
                }}
                variant={selectedFilter === value ? "filled" : "outlined"}
                sx={{borderRadius: 2}}
              />
            ))}
          </Stack>
        </div>

        <div className="LongTermApplications__header-wrapper">
          <div className="ShortTermApplications__left-section">

            <img
              alt="chevron left icon"
              src={chevronLeft}
              className="LongTermApplications__back-icon"
              onClick={() => navigate(-1)}
            />
            <h2 className="LongTermApplications__title">
              {t("jobs.applications.applicantsForJob")}: {selectedLanguage === "SQ"
              ? `${job.job_title.title?.sq}`
              : `${job.job_title.title?.en}`}
            </h2>
            <Chip
              key={"download"}
              label={t("download")}
              icon={<Download/>}
              clickable
              onClick={() => {
                // download csv
                authCtx.isOrganizationBusinessPlusOrPro ? downloadApplicantsAsCSV() : setShowPermissionRequiredModal(true)
              }}
              variant={"outlined"}
              sx={{borderRadius: 2}}
            />
          </div>
          &nbsp; &nbsp; &nbsp;
          <div className="ShortTermApplications__toggleTable">
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <Typography>{t("cards")}</Typography>
              <AntSwitch
                checked={showAsTable}
                inputProps={{'aria-label': 'ant design'}}
                onChange={() => {
                  setShowAsTable(!showAsTable);
                  localStorage.setItem("showAsTable", (!showAsTable).toString());
                }}
              />
              <Typography>{t("table")}</Typography>
            </Stack>
          </div>
        </div>
      </div>
      {!isLoading && allApplications?.length === 0 && (
        <div className="LongTermApplications__no-content">
          {t("jobs.applications.noContent")}
        </div>
      )}
      {isLoading && loading && allApplications?.length === 0 && (
        <div className="ShortTermApplications__no-content">
          <LoadingComponent/>
        </div>
      )}
      {showAsTable ? (

        allApplications && (
          <div className="ShortTermApplications__table">
            {allApplications.length > 0 && (
              <ApplicationTable
                applications={allApplications}
                onViewClick={(selectedApplicant) => {
                  setSelectedApplicant(selectedApplicant);
                  setOpenSidebar(true);
                }}
              />
            )}
          </div>
        )
      ) : (
        <div className="LongTermApplications__cards-wrapper">
          {allApplications &&
            allApplications.map((person: any, index: number) => (
              <div key={index} className="Applications__card">
                <ApplicationCard
                  person={person}
                  jobName={
                    selectedLanguage === "SQ"
                      ? job.job_title.title?.sq
                      : job.job_title.title?.en
                  }
                  setSelectedApplicant={setSelectedApplicant}
                  setOpenSidebar={setOpenSidebar}
                  refreshList={refresh}
                />
              </div>
            ))}
        </div>
      )}
      <div>
        {nextPage && nextPage !== null && (
          <div ref={sentryRef}>
            <LoadingComponent/>
          </div>
        )}
      </div>
      {isLoading && !loading && allApplications?.length === 0 && (
        <div className="LongTermApplications__no-content">
          <LoadingComponent/>
        </div>
      )}
      {openRightSidebar && (
        <div>
          <RightSidebar
            onClose={() => hideRightSidebar()}
            sidebarRef={rightSidebarRef}
          >
            <ApplicantSidebar
              person={selectedApplicant}
              jobName={
                selectedLanguage === "SQ"
                  ? job.job_title.title?.sq
                  : job.job_title.title?.en
              }
              jobId={job.id}
              onClose={() => hideRightSidebar()}
              loadMore={refresh}
            />
          </RightSidebar>
        </div>
      )}

      {showPermissionRequiredModal && (
        <WarningModal
          title={t("thisFeatureIsAvailableOnlyForSubscribers") + " " + t("contactSalesForSubscription")}
          buttonLabel={t("seePlans")}
          onCancel={() => setShowPermissionRequiredModal(false)}
          onSubmit={() => navigate("/plans")}
          hideCancelBtn={true}
        />
      )}

    </section>
  );
};

export default LongTermApplications;
