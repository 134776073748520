import React, {useState} from "react";

import RoutesContainer from "./routes/RoutesContainer";
import {GlobalContextProvider} from "./context/GlobalContext/GlobalContextProvider";
import {AuthContextProvider} from "./context/AuthContext/AuthContextProvider";
import {I18nextProvider} from "react-i18next";
import i18n from "i18next";
import {resources} from "./translations/resources";
import {Fab} from "@mui/material";
import {LiveHelp, WhatsApp} from "@mui/icons-material";
import "./App.scss";
import ContactModal from "./components/shared/ContactModal/ContactModal";

function App() {
  const langFromLocalStorage = localStorage.getItem("lang");
  const [isHelpOpen, setIsHelpOpen] = React.useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  if (!langFromLocalStorage) {
    localStorage.setItem("lang", "SQ");
  }

  i18n
    .init({
      lng: langFromLocalStorage || "SQ",
      resources,
    })
    .then(() => {
    });

  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <GlobalContextProvider>
          <AuthContextProvider>
            <RoutesContainer/>
          </AuthContextProvider>
        </GlobalContextProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
